<template>
    <div class="container">

        <div class="loader" v-if="skeleton">
            <img width="200" loading="lazy" height="200" alt="loader" src="~/assets/assets/loader.svg" />
        </div>

        <div v-else>

            <div class="row user_block">
                <div class="col-md-6 col-lg-5 mx-auto">
                <div class="title_box">
                    <h1 class="title font-active">Login</h1>
                </div>
                <AuthTheLogin :page="'login'" /> 
                <div class="row mt-2">
                    <div class="col-md-12">
                    <p class="register_cta">Don’t have an account? <nuxt-link :to="localePath('/register')">Sign Up</nuxt-link></p>
                    </div>
                </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script setup>
    import { useAppConfig } from "#app";
    import { ofetch } from 'ofetch'
    import setCsrfToken from "~/composables/csrf/setCsrfToken";
    
    useFetchMeta( useNuxtApp(), useRuntimeConfig() )
    const localePath = useLocalePath()
    const redirect = ref('/account/detail')
    const router = useRouter()
    const { logout, isAuthenticated  } = useSanctumAuth();
    const user = useSanctumUser()
    const skeleton =  ref(true)

    async function loggedout(){
        await setCsrfToken()
        await logout()
    }

    if(isAuthenticated.value){
        navigateTo(localePath('/account/detail'))
    }

    const isAuth = async () => {
        try {
            if (isAuthenticated.value) {
                await router.push('/account/detail')
            }
        } catch (error) {
            console.error('Router navigation error:', error)
        }
    }


    onMounted( () =>{

        setTimeout( () => {


            skeleton.value = false
        }, 1500)
        
    } )


    // await isAuth()
   
</script>

<style scoped>


.loader{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}


.register_cta{
    text-align: center;
    font-size: 16px;
}
.register_cta a{
    color: #70c099;
    font-size: 16px;
}
.user_block {
    margin: 6% 0;
}

h1.title {
    font-size: 38px;
    line-height: normal;
}

.title_box {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 3%;
    margin-bottom: 6%;
}

@media(max-width: 767px){
    h1.title{
        font-size: 30px;
    }
    .register_cta{
        text-align: left;
    }
    .user_block {
        /* margin-top: 20%; */
    }
}
</style>
